module.exports = [{
      plugin: require('/home/adam/Projects/adamnicholson-whoops-marketing-frontend/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/adam/Projects/adamnicholson-whoops-marketing-frontend/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-126406977-1","respectDNT":true},
    },{
      plugin: require('/home/adam/Projects/adamnicholson-whoops-marketing-frontend/node_modules/gatsby-plugin-drift/gatsby-browser.js'),
      options: {"plugins":[],"appId":"vxfupn4yabgh"},
    },{
      plugin: require('/home/adam/Projects/adamnicholson-whoops-marketing-frontend/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
