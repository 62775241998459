// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/adam/Projects/adamnicholson-whoops-marketing-frontend/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/home/adam/Projects/adamnicholson-whoops-marketing-frontend/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-beta-launch-js": () => import("/home/adam/Projects/adamnicholson-whoops-marketing-frontend/src/pages/article/beta-launch.js" /* webpackChunkName: "component---src-pages-article-beta-launch-js" */),
  "component---src-pages-article-onprem-agent-js": () => import("/home/adam/Projects/adamnicholson-whoops-marketing-frontend/src/pages/article/onprem-agent.js" /* webpackChunkName: "component---src-pages-article-onprem-agent-js" */),
  "component---src-pages-article-onprem-agents-private-beta-js": () => import("/home/adam/Projects/adamnicholson-whoops-marketing-frontend/src/pages/article/onprem-agents-private-beta.js" /* webpackChunkName: "component---src-pages-article-onprem-agents-private-beta-js" */),
  "component---src-pages-bot-js": () => import("/home/adam/Projects/adamnicholson-whoops-marketing-frontend/src/pages/bot.js" /* webpackChunkName: "component---src-pages-bot-js" */),
  "component---src-pages-index-js": () => import("/home/adam/Projects/adamnicholson-whoops-marketing-frontend/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/home/adam/Projects/adamnicholson-whoops-marketing-frontend/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-support-js": () => import("/home/adam/Projects/adamnicholson-whoops-marketing-frontend/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-js": () => import("/home/adam/Projects/adamnicholson-whoops-marketing-frontend/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/adam/Projects/adamnicholson-whoops-marketing-frontend/.cache/data.json")

